import { useEffect } from 'react';
import styled from 'styled-components';
import StravaActivity from './StravaActivity';

const Activities = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const StravaActivities = () => {
    useEffect(() => {
      const body = document.querySelector('body');
      const script = document.createElement('script');
      script.src = 'https://strava-embeds.com/embed.js';
      body.appendChild(script);

      return () => {
        body.removeChild(script);
      };
    }, []);

    const activities = [
      11720395276, 
      11877514758, 
      12010793542, 
      12017715467, 
      12360020198,
      12523230325,
      12530680398,
      12636007196,
      12859175147,
      13113571823,
      13326280992,
    ];

    return (
      <Activities>
        {activities.map((activityId) => <StravaActivity key={`activity-${activityId}`} activityId={activityId}/>)}
      </Activities>
      );
};

export default StravaActivities;
